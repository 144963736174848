import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Landing",
    component: () => import("@/views/Landing"),
    meta: {
      auth: false,
      layout: "default-layout",
    },
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      auth: false,
      layout: "auth-layout",
      title: "Login",
      pageTitle: "authorisation",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/api-keys",
    name: "Api",
    component: () => import("@/views/Api"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/finances",
    name: "Finances",
    component: () => import("@/views/Finances"),
    meta: {
      auth: true,
      layout: "panel-layout",
    }
  },
  {
    path: "/feeds",
    name: "Feeds",
    component: () => import("@/views/Feeds"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
  {
    path: "/stats",
    name: "Statistics",
    component: () => import("@/views/Statistics"),
    meta: {
      auth: true,
      layout: "panel-layout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0};
  },
});

router.beforeEach((to, from, next) => {
  const isAuthorized = localStorage.getItem("authToken");
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (to.meta.auth === true && !isAuthorized) {
    next("/login");
  }
  if (to.meta.auth === false && isAuthorized) {
    next("/feeds");
  }
  if (to.path === "/auth") {
    next("/login");
  }
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) {
    return next();
  }

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));
});

export default router;
