<template>
  <aside
    :class="{ active: isOPen, mini: colapsedMenu && isDesktop }"
    class="sidebar"
  >
    <div class="sidebar__head">
      <router-link class="sidebar__logo" to="/">
        <img
          v-if="colapsedMenu"
          :src="require('@/assets/images/svg/logo-sm.svg')"
          class="logo-mini"
        />
        <img v-else :src="require('@/assets/images/svg/logo.svg')" alt="" />
      </router-link>
    </div>

    <div class="sidebar__content">
      <div class="sidebar__info">
        <div class="sidebar__lang language">
          <selectLanguage leftMenu />
        </div>
        <div class="sidebar__wallet">
          <div class="wallet">
            <div class="wallet__icon align-center">
              <img src="@/assets/images/svg/$.svg" alt="" />
            </div>
            <div class="wallet__label">{{ $t("header.balance") }}</div>
            <div class="wallet__value">{{ numberFormat(userBalance) }} $</div>
          </div>
        </div>
      </div>
      <transition name="slide">
        <nav
          :class="{ disable: user && user.ban_reason }"
          class="sidebar__menu menu"
        >
          <ul>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/feeds"
                @click.native="close"
              >
                <div class="menu__icon">
                  <img src="@/assets/images/svg/feeds.svg" alt="" />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("sidebar.feed")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/api-keys"
                @click.native="close"
              >
                <div class="menu__icon">
                  <img src="@/assets/images/svg/pc.svg" alt="" />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("sidebar.api")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/stats"
                @click.native="close"
              >
                <div class="menu__icon">
                  <img src="@/assets/images/svg/stats.svg" alt="" />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("sidebar.stats")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                active-class="active"
                :class="{ mini: colapsedMenu && isDesktop }"
                exact
                to="/finances"
                @click.native="close"
              >
                <div class="menu__icon">
                  <img src="@/assets/images/svg/wallet.svg" alt="" />
                </div>
                <span v-show="!colapsedMenu || !isDesktop" class="menu__text">{{
                  $t("sidebar.finances")
                }}</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </transition>
      <button class="btn btn-logout" @click="logout">
        <img
          src="@/assets/images/svg/off.svg"
          :class="{ 'mr-1': !colapsedMenu }"
        />
        {{ !colapsedMenu ? $t("logout") : "" }}
      </button>
      <div class="sidebar__manager">
        <div class="manager">
          <div class="manager__item">
            <div class="manager__icon align-center">
              <img src="@/assets/images/svg/telegram.svg" alt="" />
            </div>
            <div class="manager__label">{{ $t("header.your_manager") }}</div>
            <a class="manager__link" href="https://t.me/Leo31PH" target="_blank"
              >@Leo31PH</a
            >
          </div>
          <div class="manager__chat">
            <div class="manager__chat-label">chat</div>
            <a
              class="manager__link"
              href="https://t.me/publisher_push_house"
              target="_blank"
              >@publisher_push_house</a
            >
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
import { mapGetters } from "vuex";
import SelectLanguage from "@/components/SelectLanguage";

export default {
  name: "MenuWrapper",
  components: { SelectLanguage },
  data: () => ({
    isDesktop: null,
  }),
  props: {
    isOPen: {},
    close: {},
    colapsedMenu: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    window.innerWidth >= 1200
      ? (this.isDesktop = true)
      : (this.isDesktop = false);

    window.addEventListener("resize", (e) => {
      e.target.innerWidth <= 1200
        ? (this.isDesktop = false)
        : (this.isDesktop = true);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize");
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    userBalance() {
      return this.user?.balance % 1 === 0
        ? this.user.balance?.toFixed(0)
        : this.user.balance?.toFixed(2);
    },
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
    numberFormat(str) {
      let num = parseInt(str, 10);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>

<style lang="scss" scoped>
li a.mini {
  width: fit-content;
  padding-left: 0;
}

.btn-logout {
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 20px 6px 20px 6px;
  background: #f7f7f9;
  color: #4c4c52;
  text-align: center;
  overflow: initial;
  &:hover {
    color: #000;
  }
}
.disable {
  pointer-events: none;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}
</style>
