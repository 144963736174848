<template>
  <div class="wrapper wrapper_in" :class="{ mini: colapsedMenu && isDesktop }">
    <PanelHeader
      @toggleMenu="toggleMenu"
      :isOPen="isOPen"
      @close="close"
      @click="close"
      @colapseMenu="colapseMenu"
    />
    <main class="main">
      <template>
        <slot />
      </template>
    </main>
    <MenuWrapper :isOPen="isOPen" :close="close" :colapsedMenu="colapsedMenu" />
    <div class="menu-bg" :class="{ active: isOPen }" @click="close"></div>
  </div>
</template>


<script>
import apiClient from "@/services/AxiosService";
import MenuWrapper from "@/components/MenuWrapper";
import PanelHeader from "@/components/PanelHeader";
import { mapActions, mapGetters } from "vuex";
import { setLangHeader } from "@/services/AxiosService";
import "@/assets/css/vendor/bootstrap.min.css";
import "@/assets/css/main.scss";

export default {
  name: "PanelLayout",
  components: { PanelHeader, MenuWrapper },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      allBrowsers: "statistics/getBrowsers",
      allCountries: "statistics/getCountries",
      allOs: "statistics/getOS",
      allFeeds: "statistics/getFeeds",
    }),
  },
  data() {
    return {
      isOPen: false,
      colapsedMenu: false,
      isDesktop: null,
    };
  },
  mounted() {
    window.innerWidth >= 1200
      ? (this.isDesktop = true)
      : (this.isDesktop = false);

    window.addEventListener("resize", (e) => {
      e.target.innerWidth <= 1200
        ? (this.isDesktop = false)
        : (this.isDesktop = true);
    });
    !this.user.id && this.setUser();
    !this.allBrowsers.length && this.setBrowsers();
    !this.allCountries.length && this.setCountries();
    !this.allOs.length && this.setOS();
    !this.allFeeds.length && this.setFeeds();
  },
  watch: {
    "$i18n.locale": function () {
      setLangHeader(localStorage.getItem("lang"));
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize");
  },
  created() {
    localStorage.getItem("colapsedMenu") === "true"
      ? (this.colapsedMenu = true)
      : (this.colapsedMenu = false);
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
      setCountries: "statistics/handleCountries",
      setBrowsers: "statistics/handleBrowsers",
      setOS: "statistics/handleOS",
      setFeeds: "statistics/handleFeeds",
    }),
    colapseMenu() {
      this.colapsedMenu = !this.colapsedMenu;
    },
    close() {
      if (!this.isOPen) {
        return;
      }
      this.isOPen = false;
    },
    toggleMenu() {
      this.isOPen = !this.isOPen;
    },
    changeLanguage() {
      const newLang = this.$i18n.locale == "ru" ? "en" : "ru";
      this.$i18n.locale = newLang;
      localStorage.setItem("lang", newLang);
    },
    async logOut() {
      await localStorage.setItem("authToken", "");
      await apiClient.get("user/logout");
    },
  },
};
</script>
<style lang="scss" scoped>
.disable {
  pointer-events: none;
}
</style>

