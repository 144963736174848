<template>
  <header :class="{ 'open-menu': isOPen }" class="header">
    <div class="content content_in">
      <button class="header__menu" @click="toggle">
        <div :class="{ active: isOPen }" class="icon-menu">
          <div class="sw-topper"></div>
          <div class="sw-bottom"></div>
          <div class="sw-footer"></div>
        </div>
      </button>
      <a class="header__logo" href="/">
        <img :src="require('@/assets/images/svg/logo.svg')" alt="" />
      </a>
      <div class="header__manager">
        <img :src="burgerMenuUrl" class="burger-menu" @click="toggleMenu" />
        <div class="manager">
          <div class="manager__item">
            <div class="manager__icon align-center">
              <img src="@/assets/images/svg/telegram.svg" alt="">
            </div>
            <div class="manager__label">{{ $t("header.your_manager") }}</div>
            <a class="manager__link" href="https://t.me/Leo31PH" target="_blank"
              >@Leo31PH</a
            >
          </div>
          <div class="manager__chat">
            <div class="manager__chat-label">chat</div>
            <a
              class="manager__link"
              href="https://t.me/publisher_push_house"
              target="_blank"
              >@publisher_push_house</a
            >
          </div>
        </div>
      </div>
      <div class="header__lang language">
        <selectLanguage />
      </div>
      <div class="header__wallet">
        <div class="wallet">
          <div class="wallet__icon align-center">
            <img src="@/assets/images/svg/$.svg" alt="">
          </div>
          <div class="wallet__label">{{ $t("header.balance") }}</div>
          <div class="wallet__value">{{ userBalance }}$</div>
        </div>
      </div>

      <div class="header__user">
        <div class="user">
          <a class="user__prev" @click.prevent="goToProfile">
            <profile-avatar />
          </a>
        </div>
         
      </div>
    </div>
  </header>
</template>
<script>
import apiClient from "@/services/AxiosService";
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import "@/assets/css/header.scss";
import SelectLanguage from "@/components/SelectLanguage";
import ProfileAvatar from "@/components/ProfileAvatar";

export default {
  name: "PanelHeader",
  components: { ProfileAvatar, SelectLanguage},
  data() {
    return {
      balance: 0,
      burgerMenuUrl: '',
    };
  },
  props: {
    isOPen: {},
    close: {},
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    userBalance() {
      return this.user?.balance % 1 === 0
        ? this.user.balance?.toFixed(0)
        : this.user.balance?.toFixed(2);
    },
  },
   mounted(){
   if(localStorage.getItem("colapsedMenu") === "true") {
        this.burgerMenuUrl = require("@/assets/images/svg/burger-menu-close.svg")
      } else {
        this.burgerMenuUrl = require("@/assets/images/svg/burger-menu-open.svg")
      }
  },
  methods: {
    toggleMenu(){
      this.$emit('colapseMenu')
      this.checkColapseState()
    },
    checkColapseState(){
      if(localStorage.getItem("colapsedMenu") === "true") {
        this.burgerMenuUrl = require("@/assets/images/svg/burger-menu-open.svg")
        localStorage.setItem("colapsedMenu", "false")
      } else {
        this.burgerMenuUrl = require("@/assets/images/svg/burger-menu-close.svg")
        localStorage.setItem("colapsedMenu", "true")
      }
    },
    goToProfile() {
      if (this.user && !this.user.ban_reason) {
        this.$emit("close");
        if (this.$route.path !== "/profile") this.$router.push("/profile");
      }
    },
    numberFormat(str) {
      let num = parseInt(str, 10);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    toggle() {
      this.$emit("toggleMenu");
    },
    hide() {
      this.close();
    },
    logOut() {
      apiClient.get("/user/logout");
      localStorage.setItem("authToken", "");
      window.location.href = "/";
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss" scoped>
.burger-menu {
  align-self: center;
  height: 25px;
  cursor: pointer;
  margin-right: 20px;
}

.multiselect {
  &.select-language {
    display: none;
  }
}
</style>